<template lang="pug">
.main-wrapper.agenda-salvar
        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else)
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3(v-if='!isModal')
                        Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                    .ta-right(:class="{ 'p-col-12': isModal, 'p-col-9': !isModal }")
                        h1.text-header.text-secondary MedClub /
                            <router-link :to='{ name: "agenda" }'> Agendas</router-link> /
                            <b>{{  model.id ? 'Editar' : 'Adicionar' }}</b>
            form(@submit.prevent='handleSubmit()')
                ProgressBar(v-if='waitingForm' mode="indeterminate")
                div(v-else)
                    Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } agenda`")
                        .p-grid.p-fluid.p-align-end

                            .p-col-12.p-md-3(:class="{ 'form-group--error': submitted && $v.model.ie_tipo_agenda.$error }")
                                label.form-label Tipo de agenda:
                                Dropdown(v-model='$v.model.ie_tipo_agenda.$model' :options='options.ie_tipo_agenda' :disabled='model.id != undefined'
                                    dataKey='value' optionLabel='text' optionValue='value' placeholder='Selecione' @change='getProcedimentos()')
                                .feedback--errors(v-if='submitted && $v.model.ie_tipo_agenda.$error')
                                    .form-message--error(v-if="!$v.model.ie_tipo_agenda.required") <b>Tipo de agenda</b> é obrigatório.

                            .p-col-12.p-md-3
                                label.form-label Setor:
                                InputText(v-model='model.ds_setor' placeholder='(Opcional)')

                            .p-col-12.p-md-6
                                label.form-label Salas:
                                ProgressBar(v-if='waitingSalas' mode="indeterminate" style='margin: 15px 0;')
                                MultiSelect(v-else v-model='model.cd_unidades' :options='options.cd_unidades'
                                    dataKey='value' optionLabel='text' optionValue='value' placeholder='Selecione' :filter='options.cd_unidades.length > 0')

                            .p-col-12.p-md-6(v-if='model.ie_tipo_agenda == "C"' :class="{ 'form-group--error': submitted && $v.model.cd_procedimento_estabelecimento.$error }")
                                label.form-label Procedimento:
                                ProgressBar(v-if='waitingProcedimentos' mode="indeterminate" style='margin: 15px 0;')
                                p(v-else-if='!options.cd_procedimento_estabelecimento.length') Sem procedimentos.
                                Dropdown(v-else v-model='$v.model.cd_procedimento_estabelecimento.$model' :options='options.cd_procedimento_estabelecimento'
                                    :filter='options.cd_procedimento_estabelecimento.length > 0' dataKey='value' optionLabel='text' optionValue='value' placeholder='Selecione')
                                    template(#option='props' class='dropdown-consultas')
                                        div.p-dropdown-item
                                            span <b>{{props.option.cd_cbhpm }} - {{props.option.nm_procedimento}}</b> <br>
                                            span(v-if='props.option.ds_especialidades.length > 0') {{props.option.ds_especialidades}} <br> 
                                            span <b>{{formatPrice(props.option.ds_preco)}}</b>
                                .feedback--errors(v-if='submitted && $v.model.cd_procedimento_estabelecimento.$error')
                                    .form-message--error(v-if="!$v.model.cd_procedimento_estabelecimento.required") <b>Procedimento</b> é obrigatório.
                           
                            
                            .p-col-12.p-md-6(v-if="['E','P','L'].includes(model.ie_tipo_agenda)" :class="{ 'form-group--error': submitted && $v.model.cd_procedimentos.$error }")
                                label.form-label Procedimento(s):
                                ProgressBar(v-if='waitingProcedimentos' mode="indeterminate" style='margin: 15px 0;')
                                MultiSelect(v-else v-model='$v.model.cd_procedimentos.$model' :options='options.cd_procedimento_estabelecimento'
                                    dataKey='value' optionLabel='text' optionValue='value' placeholder='Selecione' :filter='options.cd_procedimento_estabelecimento.length > 0')
                                    template(#option='props')
                                        div.slot-itens-multiselect
                                            span <b>{{ props.option.cd_cbhpm }} - {{ props.option.nm_procedimento }}</b><br>
                                            div
                                                span(v-if="props.option.ds_sigla") {{ props.option.ds_sigla }} -
                                                span {{ props.option.nm_procedimento }}
                                                span(v-if="props.option.ds_sinonimia")  ({{ props.option.ds_sinonimia }})
                                .feedback--errors(v-if='submitted && $v.model.cd_procedimentos.$error')
                                    .form-message--error(v-if="!$v.model.cd_procedimentos.required") <b>Procedimento</b> é obrigatório.

                            .p-col-12.p-md-6(:class="{ 'form-group--error': submitted && $v.model.cd_especialista.$error }")
                                label.form-label Especialista:
                                ProgressBar(v-if='waitingEspecialistas' mode="indeterminate" style='margin: 15px 0;')
                                p(v-else-if='!options.cd_especialista.length') Sem especialistas.
                                Dropdown(v-else v-model='$v.model.cd_especialista.$model' :options='options.cd_especialista' :disabled='especialistaDesabilitado'
                                    :filter='options.cd_especialista.length > 0' dataKey='value' optionLabel='text' optionValue='value' placeholder='Selecione')
                                .feedback--errors(v-if='submitted && $v.model.cd_especialista.$error')
                                    .form-message--error(v-if="!$v.model.cd_especialista.required") <b>Especialista</b> é obrigatório.

                            .p-col-12.p-as-start(style='align-self: start')
                                .p-grid.p-fluid.p-align-start
                                    .p-col-12.p-md-6(v-if='model.ie_tipo_agenda == "C"' :class="{ 'form-group--error': submitted && $v.model.cd_especialidades.$error }")
                                        label.form-label Especialidade:
                                        ProgressBar(v-if='waitingEspecialidades' mode="indeterminate" style='margin: 15px 0;')
                                        p(v-else-if='!options.cd_especialidade.length') Sem especialidades.
                                        MultiSelect(v-else v-model='$v.model.cd_especialidades.$model' :options='options.cd_especialidade' :filter='options.cd_especialidade.length > 0'
                                            placeholder='Selecione' optionLabel="text" optionValue='value' @input='checkEspecialidades')
                                        .feedback--errors(v-if='submitted && $v.model.cd_especialidades.$error')
                                            .form-message--error(v-if="!$v.model.cd_especialidades.required") <b>Especialidade</b> é obrigatório.

                                    .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.ie_sexo.$error }")
                                        label.form-label Sexo:
                                        Dropdown(v-model='$v.model.ie_sexo.$model' :options='options.ie_sexo'
                                            optionLabel='text' optionValue='value' placeholder='Selecione')
                                        .feedback--errors(v-if='submitted && $v.model.ie_sexo.$error')
                                            .form-message--error(v-if="!$v.model.ie_sexo.required") <b>Sexo</b> é obrigatório.

                                    .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.nr_idade_minima.$error }")
                                        label.form-label Idade mínima:
                                        InputNumber(v-model='$v.model.nr_idade_minima.$model' mode='decimal' :min="-1" :max="121" v-tooltip.top="'Idade mínima 0'")
                                        .feedback--errors(v-if='submitted && $v.model.nr_idade_minima.$error')
                                            .form-message--error(v-if="!$v.model.nr_idade_minima.required") <b>Idade mínima</b> é obrigatório.

                                    .p-col-12.p-md-2(:class="{ 'form-group--error': submitted && $v.model.nr_idade_maxima.$error }")
                                        label.form-label Idade máxima:
                                        InputNumber(v-model='$v.model.nr_idade_maxima.$model' mode='decimal' :min="-1" :max="121" v-tooltip.top="'Idade máxima 120'")
                                        .feedback--errors(v-if='submitted && $v.model.nr_idade_maxima.$error')
                                        .form-message--error(v-if="!$v.model.nr_idade_maxima.required") <b>Idade máxima</b> é obrigatório.

                            .p-col-12.p-md-4(v-if="model.id_externo || model.ds_tipo_integracao")
                                label.form-label Id Integração
                                .p-inputgroup
                                    InputNumber(v-model='model.id_externo' placeholder='(Opcional)')
                                    Button(:label="dialogIntegracao ? 'Fechar' : 'Localizar'" type="button" @click='dialogIntegracao=!dialogIntegracao' v-if="model.ds_tipo_integracao")

                            .p-col-12.p-as-start.box-integracao(style='align-self: start' v-show='dialogIntegracao && model.ds_tipo_integracao=="ATHENA"')
                                .p-grid.p-fluid.p-align-start
                                    .p-col-12.p-md-3
                                        label.form-label Unidade
                                        ProgressBar(v-if='waitingIntegracaoUnidades' mode="indeterminate")
                                        Dropdown(v-else v-model='integracao.unidade' :options='options.integracao_unidades' :disabled='$v.model.ie_tipo_agenda.$model == ""'
                                            dataKey='value' :filter="true" optionLabel='text' optionValue='value' placeholder='Selecione' @change='integracaoAtualizaEspecialidades()')
                                    .p-col-12.p-md-3(v-if="$v.model.ie_tipo_agenda.$model == 'C'")
                                        label.form-label Especialidade
                                        ProgressBar(v-if='waitingIntegracaoEspecialidade' mode="indeterminate")
                                        Dropdown(v-else v-model='integracao.especialidade' :options='options.integracao_especialidades' :disabled='$v.model.ie_tipo_agenda.$model == ""'
                                            dataKey='value' :filter="true" optionLabel='text' optionValue='value' placeholder='Selecione' @change='integracaoAtualizaAgendasConsulta()')
                                    .p-col-12.p-md-3
                                        label.form-label Agenda
                                        ProgressBar(v-if='waitingIntegracaoAgenda' mode="indeterminate")
                                        AutoComplete(v-else v-model="integracao.agenda" :suggestions="options.integracao_agendas" field="text"
                                            :forceSelection="true" :disabled='$v.model.ie_tipo_agenda.$model == ""' :dropdown="true" @complete="filterAgenda($event)" )
                                            template(#item="props")
                                                div {{props.item.text}}

                                        //Dropdown(v-else v-model='integracao.agenda' :options='options.integracao_agendas' :disabled='$v.model.ie_tipo_agenda.$model == ""'
                                            dataKey='value' :filter="true" optionLabel='text' @filter="filterAgenda()" optionValue='value' placeholder='Selecione')
                                    .p-col-12.p-md-3(style="text-align:right")
                                        label.form-label <br />
                                        Button(icon="jam jam-check" type="button" @click='selecionaAgenda();dialogIntegracao=false')
                                        Button.p-button-secondary(icon="jam jam-close" type="button" @click='dialogIntegracao=false')

                            .p-col-12.p-as-start.box-integracao(style='align-self: start' v-show='dialogIntegracao && model.ds_tipo_integracao=="SISAC"')
                                .p-grid.p-fluid.p-align-start
                                    .p-col-12.p-md-5
                                        label.form-label Agenda
                                        Dropdown(v-model='integracao.agenda' :options='options.integracao_agendas' :disabled='$v.model.ie_tipo_agenda.$model == ""'
                                            dataKey='value' optionLabel='text' optionValue='value' placeholder='Selecione')
                                    .p-col-12.p-md-2(style="text-align:right")
                                        label.form-label <br />
                                        Button(icon="jam jam-check" type="button" @click='selecionaAgenda();dialogIntegracao=false')
                                        Button.p-button-secondary(icon="jam jam-close" type="button" @click='dialogIntegracao=false')

                            .p-col-12.p-as-start.box-integracao(style='align-self: start' v-show='dialogIntegracao && model.ds_tipo_integracao=="SWALLIANCE"')
                                .p-grid.p-fluid.p-align-start
                                    .p-col-12.p-md-3
                                        label.form-label Unidade
                                        ProgressBar(v-if='waitingIntegracaoUnidades' mode="indeterminate")
                                        Dropdown(v-else v-model='integracao.unidade' :options='options.integracao_unidades' :disabled='$v.model.ie_tipo_agenda.$model == ""'
                                            dataKey='value' :filter="true" optionLabel='text' optionValue='value' placeholder='Selecione' @change='integracaoAtualizaAgendasConsulta()')
                                    //.p-col-12.p-md-3(v-if="$v.model.ie_tipo_agenda.$model == 'C'")
                                    //    label.form-label Especialidade
                                    //    ProgressBar(v-if='waitingIntegracaoEspecialidade' mode="indeterminate")
                                    //    Dropdown(v-else v-model='integracao.especialidade' :options='options.integracao_especialidades' :disabled='$v.model.ie_tipo_agenda.$model == ""'
                                    //        dataKey='value' :filter="true" optionLabel='text' optionValue='value' placeholder='Selecione' @change='integracaoAtualizaAgendasConsulta()')
                                    .p-col-12.p-md-3
                                        label.form-label Agenda
                                        ProgressBar(v-if='waitingIntegracaoAgenda' mode="indeterminate")
                                        AutoComplete(v-else v-model="integracao.agenda" :suggestions="options.integracao_agendas" field="text"
                                            :forceSelection="true" :disabled='$v.model.ie_tipo_agenda.$model == ""' :dropdown="true" @complete="filterAgenda($event)" )
                                            template(#item="props")
                                                div {{props.item.text}}

                                        //Dropdown(v-else v-model='integracao.agenda' :options='options.integracao_agendas' :disabled='$v.model.ie_tipo_agenda.$model == ""'
                                            dataKey='value' :filter="true" optionLabel='text' @filter="filterAgenda()" optionValue='value' placeholder='Selecione')
                                    .p-col-12.p-md-3(style="text-align:right")
                                        label.form-label <br />
                                        Button(icon="jam jam-check" type="button" @click='selecionaAgenda();dialogIntegracao=false')
                                        Button.p-button-secondary(icon="jam jam-close" type="button" @click='dialogIntegracao=false')

                            .p-col-12
                                .p-grid.p-fluid.p-align-end
                                    .p-col-12.p-md-2.ta-center
                                        label.form-label Hora marcada:
                                        InputSwitch(v-model='model.ie_hora_marcada')

                                    .p-col-12.p-md-2.ta-center
                                        label.form-label Presencial:
                                        InputSwitch(v-model='model.ie_agenda_presencial')

                                    .p-col-12.p-md-2.ta-center
                                        label.form-label Restrita:
                                        InputSwitch(v-model='model.ie_agenda_restrita')

                                    .p-col-12.p-md-2.ta-center
                                        label.form-label Habilitada:
                                        InputSwitch(v-model='model.ie_status')

                                    .p-col-12.p-md-4.ta-center
                                        label.form-label Agenda aberta (uso local):
                                        InputSwitch(v-model='model.ie_agenda_aberta')

                            .p-col-12.mt-2.mb-2(v-if="model.id")
                                .p-grid
                                    .p-col-12.p-md-6.aux-2
                                        span <b>Usuário criação: </b> {{ model.nm_usuario_cri }}
                                    .p-col-12.p-md-6.aux-2
                                        span <b>Data da criação: </b> {{ model.dt_criado_f }}
                                    .p-col-12.p-md-6.aux-2
                                        span <b>Usuário edição: </b> {{ model.nm_usuario_edi }}
                                    .p-col-12.p-md-6.aux-2
                                        span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}

                            .p-col-12.p-md-6
                                label.form-label Observações:
                                Textarea.textarea-informacoes(v-model='model.ds_observacao' placeholder='(Opcional)' :autoResize="true" rows="5")

                            .p-col-12.p-md-6
                                label.form-label Termo:
                                Textarea.textarea-informacoes(v-model='model.ds_termo_pronto_socorro' placeholder='(Opcional)' :autoResize="true" rows="5")

                            .p-col-12.p-md-6(v-if="['E','P','L'].includes(model.ie_tipo_agenda)")
                                label.form-label Informações de preparo:
                                Textarea.textarea-preparo(v-model='model.ds_orientacao' placeholder='(Opcional)' :autoResize="true" rows="5")

                            .p-col-12
                                .p-grid.p-fluid.p-align-end
                                    .p-col-12.p-md-2.ta-center
                                        Button(label='Salvar' type="submit")

</template>

<style lang="scss">
    .agenda-salvar {
        .p-dialog-content {
            overflow-y: scroll !important;
        }
        .aux-1 { text-align: center; @media all and (max-width: 576px) { text-align: left; } }
        .p-dropdown-filter-container {
            width: 100%;
        }
        .p-multiselect-header {
            .p-multiselect-filter-container {
                width: 97% !important;
            }
            .p-checkbox {
                display: none;
            }
        }
        .slot-itens-multiselect {
            display: inline-grid;
            max-width: 330px;
            word-wrap: break-word;
            white-space: initial;
        }
        .dropdown-consultas{
            .p-dropdown-item {
                display: inline-grid;
                max-width: 330px;
                word-wrap: break-word;
                white-space: initial;
            }
        }
        .p-picklist-source-controls,
        .p-picklist-target-controls {
            display: none;
        }
        .p-picklist-source-wrapper,
        .p-picklist-target-wrapper {
            width: 45%;
            @media all and (max-width: 576px) {
                width: 100%;
            }
        }
        .p-picklist-buttons-cell {
            text-align: center;
            .p-button {
                display: none !important;
                &:first-of-type, &:nth-of-type(3) {
                    display: inline-block !important;
                }
            }
        }
        .textarea-informacoes,
        .textarea-preparo {
            height: 200px !important;
            overflow-y: auto !important;
            resize: none;
        }

        .box-integracao{border:1px solid #CCC; background:#f7f7f7;}
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import Button from 'primevue/button'
    import InputSwitch from 'primevue/inputswitch'
    import Dropdown from 'primevue/dropdown'
    import MultiSelect from 'primevue/multiselect'
    import SelectButton from 'primevue/selectbutton'
    import InputNumber from 'primevue/inputnumber'
    import InputText from 'primevue/inputtext'
    import Textarea from 'primevue/textarea'
    import Tooltip from 'primevue/tooltip'
    import AutoComplete from 'primevue/autocomplete'


    import { Agenda, Especialista, UtilsIntegracao, UnidadesMedclub } from './../../middleware'
    import { required, requiredIf } from 'vuelidate/lib/validators'
    import moment from "moment"

    export default {
        components: {
            ProgressBar, Panel, Button, InputSwitch, Textarea, MultiSelect,
            Dropdown, SelectButton, InputNumber, InputText, Tooltip, AutoComplete
        },
        directives: { tooltip: Tooltip },
        props: [ 'isModal' ],
        created () {
            this.getSalas()

            let id = 0
            if (! isNaN(this.$route.params.id)) { id = parseInt(this.$route.params.id) }
            if (id != 0) {
                Agenda.find(id).then(response => {
                    if (response.status == 200) {
                        let keys = Object.keys(this.model)
                        keys.forEach(key => this.model[key] = response.data[key])

                        this.model.id = id
                        this.model.dt_criado_f = moment(this.model.dt_criado).format('DD/MM/YYYY HH:mm')
                        if (this.model.dt_atualizado) {
                            this.model.dt_atualizado_f = moment(this.model.dt_atualizado).format('DD/MM/YYYY HH:mm')
                        }

                        if(response.data.cd_procedimento_estabelecimento !== undefined && response.data.cd_procedimento_estabelecimento !== null) {
                            this.model.cd_procedimento_estabelecimento = response.data.cd_procedimento_estabelecimento.id

                            if(['E','L'].includes(this.model.ie_tipo_agenda)) {
                                this.model.cd_procedimentos.push({
                                    cd_procedimento_estabelecimento: response.data.cd_procedimento_estabelecimento.id,
                                    ds_procedimento: response.data.cd_procedimento_estabelecimento.ds_observacao,
                                });
                            } else {
                                this.model.cd_procedimento_estabelecimento = response.data.cd_procedimento_estabelecimento.id
                            }
                        }
                        if (response.data.cd_procedimentos !== undefined && response.data.cd_procedimentos.length > 0) {
                            let procedimentos = this.model.cd_procedimentos
                            this.model.cd_procedimentos = []
                            procedimentos.forEach(e => this.model.cd_procedimentos.push(e.cd_procedimento_estabelecimento))
                        }

                        let especialidades = this.model.cd_especialidades
                        this.model.cd_especialidades = []
                        especialidades.forEach(e => this.model.cd_especialidades.push(e.cd_especialidade))

                        // if (this.model.cd_especialista) this.especialistaDesabilitado = true
                        if (this.model.cd_especialista && this.model.ie_tipo_agenda == "C") this.especialistaDesabilitado = true

                        this.getProcedimentos()
                        this.startIntegracao()
                    }
                    this.waiting = false
                })
            } else {
                this.waiting = false
            }
        },
        data () {
            return {
                model: {
                    ie_tipo_agenda: null,
                    cd_especialista: null,
                    cd_especialidade: null,
                    cd_estabelecimento: null,
                    cd_especialidades: [],
                    cd_procedimentos: '',
                    cd_procedimento_estabelecimento: '',
                    ie_hora_marcada: false,
                    ie_agenda_restrita: false,
                    ie_agenda_presencial: false,
                    ie_agenda_aberta: false,
                    ie_status: false,
                    ie_sexo: '',
                    nr_idade_minima: 0,
                    nr_idade_maxima: 120,
                    ds_observacao: '',
                    ds_termo_pronto_socorro: '',
                    ds_orientacao: '',
                    ds_setor: '',
                    id_externo: null,
                    ds_tipo_integracao: null,
                    nm_usuario_cri: '',
                    nm_usuario_edi: '',
                    dt_criado: null,
                    dt_atualizado: null,
                    cd_unidades: []
                },
                options: {
                    ie_tipo_agenda: [
                        { text: 'Consulta', value: 'C' },
                        { text: 'Exame', value: 'E' },
                        { text: 'Laboratório', value: 'L' },
                        { text: 'Procedimento', value: 'P' }
                    ],
                    ie_sexo: [
                        { text: 'Masculino', value: 'M' },
                        { text: 'Feminino', value: 'F' },
                        { text: 'Não aplicável', value: 'N' }
                    ],
                    cd_especialista: [],
                    cd_especialidade: [],
                    cd_procedimento_estabelecimento: [],
                    integracao_unidades: [],
                    integracao_especialidades: [],
                    integracao_agendas: [],
                    cd_unidades: []
                },
                waiting: true,
                waitingForm: false,
                waitingIntegracaoUnidades:false,
                waitingProcedimentos: false,
                waitingEspecialistas: false,
                waitingEspecialidades: false,
                waitingIntegracaoEspecialidade:false,
                waitingIntegracaoAgenda:false,
                waitingSalas: false,
                submitted: false,
                dialogIntegracao: false,
                especialistaDesabilitado: false,
                integracao:{
                    unidade: null,
                    especialidade: null,
                    agenda: null
                }
            }
        },
        validations () {
            let v = {
                model: {
                    ie_tipo_agenda: { required },
                    cd_especialista: { required: requiredIf(function (model) { return model.ie_tipo_agenda == 'C' }) },
                    cd_especialidades: { required: requiredIf(function (model) { return model.ie_tipo_agenda == 'C' }) },
                    cd_procedimentos: { required: requiredIf(function (model) { return model.ie_tipo_agenda == 'E' }) },
                    cd_procedimento_estabelecimento: { required: requiredIf(function (model) { return model.ie_tipo_agenda == 'C' }) },
                    ie_sexo: { required },
                    nr_idade_minima: { required },
                    nr_idade_maxima: { required },
                }
            }
            return v
        },
        methods: {
            formatPrice (val) {
                return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
            },
            filterAgenda(a){
                if( this.model.ie_tipo_agenda == 'E' ){
                    UtilsIntegracao.getAgendasExames({
                                "cd_estabelecimento": this.model.cd_estabelecimento,
                                "cd_unidade": this.integracao.unidade,
                                "cd_especialidade": this.integracao.especialidade,
                                'ds_agenda': a.query.toUpperCase()
                            }).then((response) => {

                        this.options.integracao_agendas = []
                        if (response.status == 200) {
                            if(response.data.httpCode == 200){
                                response.data.result.forEach(agenda => {
                                    this.options.integracao_agendas.push({ text: `${ agenda.codigo_agenda} - ${ agenda.descricao_agenda }`, value: agenda.codigo_agenda })
                                })
                            }
                        }
                    })
                } else {
                    //this.integracaoAtualizaAgendasConsulta()
                    UtilsIntegracao.getAgendasConsulta({
                                "cd_estabelecimento": this.model.cd_estabelecimento,
                                "cd_unidade": this.integracao.unidade,
                                "cd_especialidade": this.integracao.especialidade,
                                'ds_agenda': a.query.toUpperCase()
                            }).then((response) => {
                        this.waitingIntegracaoAgenda = false

                        this.options.integracao_agendas = []
                        if (response.status == 200) {
                            response.data.forEach(agenda => {
                                this.options.integracao_agendas.push({ text: `${ agenda.id} - ${ agenda.ds_agenda }`, value: agenda.id })
                            })
                        }

                    })
                }
            },
            startIntegracao(){
                this.waitingIntegracaoUnidades = true
                if( this.model.ds_tipo_integracao == 'ATHENA' ){

                    UtilsIntegracao.getUnidades(
                            {"cd_estabelecimento": this.model.cd_estabelecimento}
                            ).then((response) => {
                        this.waitingIntegracaoUnidades = false

                        this.options.integracao_unidades = []
                        if (response.status == 200) {
                            response.data.result.forEach(unidade => {
                                this.options.integracao_unidades.push({ text: `${ unidade.id} - ${ unidade.nome }`, value: unidade.id })
                            })
                        }

                    })

                } else if( this.model.ds_tipo_integracao == 'SISAC' ){

                    this.waitingIntegracaoAgenda = true
                    UtilsIntegracao.getAgendasConsulta(
                            {"cd_estabelecimento": this.model.cd_estabelecimento}
                            ).then((response) => {
                        this.waitingIntegracaoAgenda = false

                        this.options.integracao_agendas = []
                        if (response.status == 200) {
                            response.data.forEach(agenda => {
                                this.options.integracao_agendas.push({ text: `${ agenda.id} - ${ agenda.ds_agenda }`, value: agenda.id })
                            })
                        }

                    })
                }else if( this.model.ds_tipo_integracao == 'SWALLIANCE' ){

                    UtilsIntegracao.getUnidades(
                        {"cd_estabelecimento": this.model.cd_estabelecimento}
                    ).then((response) => {
                        this.waitingIntegracaoUnidades = false

                        this.options.integracao_unidades = []
                        if (response.status == 200) {
                            response.data.result.forEach(unidade => {
                                this.options.integracao_unidades.push({ text: `${ unidade.id} - ${ unidade.nome }`, value: unidade.id })
                            })
                        }

                    })

                }
            },
            selecionaAgenda(){
                if (typeof this.integracao.agenda === 'object'){
                    this.model.id_externo = this.integracao.agenda.value
                } else {
                    this.model.id_externo = this.integracao.agenda
                }

            },
            integracaoAtualizaEspecialidades(){
                if( this.model.ie_tipo_agenda == 'E' ){
                    this.integracaoAtualizaAgendasExame()
                    return
                }
                this.waitingIntegracaoEspecialidade = true

                this.options.integracao_agendas = []
                this.options.integracao_especialidades = []

                UtilsIntegracao.getEspecialidades({
                            "cd_estabelecimento": this.model.cd_estabelecimento,
                            "cd_unidade": this.integracao.unidade
                        }).then((response) => {

                    this.waitingIntegracaoEspecialidade = false

                    this.options.integracao_especialidades = []
                    if (response.status == 200) {
                        response.data.result.forEach(especialidade => {
                            this.options.integracao_especialidades.push({ text: `${ especialidade.id} - ${ especialidade.descricao }`, value: especialidade.id })
                        })
                    }

                })
            },
            integracaoAtualizaAgendasConsulta(){
                this.waitingIntegracaoAgenda = true
                UtilsIntegracao.getAgendasConsulta({
                            "cd_estabelecimento": this.model.cd_estabelecimento,
                            "cd_unidade": this.integracao.unidade,
                            "cd_especialidade": this.integracao.especialidade,
                        }).then((response) => {
                    this.waitingIntegracaoAgenda = false

                    this.options.integracao_agendas = []
                    if (response.status == 200) {
                        response.data.forEach(agenda => {
                            this.options.integracao_agendas.push({ text: `${ agenda.id} - ${ agenda.ds_agenda }`, value: agenda.id })
                        })
                    }

                })
            },
            integracaoAtualizaAgendasExame(){
                this.waitingIntegracaoAgenda = true
                UtilsIntegracao.getAgendasExames({
                            "cd_estabelecimento": this.model.cd_estabelecimento,
                            "cd_unidade": this.integracao.unidade,
                            "cd_especialidade": this.integracao.especialidade,
                        }).then((response) => {

                    this.waitingIntegracaoAgenda = false

                    this.options.integracao_agendas = []
                    if (response.status == 200) {
                        response.data.result.forEach(agenda => {
                            this.options.integracao_agendas.push({ text: `${ agenda.codigo_agenda} - ${ agenda.descricao_agenda }`, value: agenda.codigo_agenda })
                        })
                    }

                })
            },
            handleSubmit () {

                this.submitted = true
                this.$v.$touch()
                if (this.$v.$invalid) return 0

                let dataSend = Object.assign({}, this.model)
                dataSend.qtd_vaga = 0

                let remove_fields = ['cd_estabelecimento', 'ds_tipo_integracao']
                for(let jj in remove_fields){
                    delete dataSend[jj]
                }

                if(this.model.ie_tipo_agenda == 'C') {
                    dataSend.cd_especialidade = dataSend.cd_especialidades[0]
                    //dataSend.cd_procedimento_estabelecimento = []
                    delete dataSend.cd_procedimentos
                } else {
                    delete dataSend.cd_especialidade
                    delete dataSend.cd_procedimento_estabelecimento
                }

                this.waitingForm = true
                Agenda.save(dataSend).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        this.waitingForm = false
                        this.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })
                        if (! dataSend.id) this.$router.push({ path: `/agenda/visualizar/${ response.data.id }/?cadastro=true` })
                        else if (dataSend.cd_especialista) this.especialistaDesabilitado = true
                    } else {
                        if (response.data.non_field_errors)
                            response.data.non_field_errors.forEach(errorMsg => {
                                this.$toast.error(errorMsg, { duration: 3000 })
                            })
                        else Object.values(response.data).forEach(errorMsg => {
                                if (typeof errorMsg == 'object')
                                    this.$toast.error(errorMsg[0], { duration: 3000 })
                                else this.$toast.error(errorMsg, { duration: 3000 })
                            })
                        this.waitingForm = false
                    }
                })
            },
            getSalas () {
                this.waitingSalas = true
                this.options.cd_unidades = []

                let params = { ie_tipo: 'ASS', ie_status: true }

                let usuario = JSON.parse(localStorage.getItem('usuario'))
                if (usuario) 
                    params.cd_estabelecimento_list = usuario.estabelecimentos[usuario.idxCurrentEstabelecimento].id
                
                UnidadesMedclub.findAllSala(params).then(response => {
                    if (response.status == 200) {
                        response.data.forEach(item => {
                            this.options.cd_unidades.push({
                                "value": item.id, "text": item.nm_sala
                            })
                        })
                    }
                    this.waitingSalas = false
                })
            },
            getProcedimentos () {

                this.options.cd_procedimento_estabelecimento = []
                this.options.cd_especialidade = []
                this.options.cd_especialista = []
                this.options.cd_procedimentos = []

                if (this.model.ie_tipo_agenda) {
                    this.waitingProcedimentos = true
                    Agenda.getProcedimentosByTipoAgenda(this.model.ie_tipo_agenda, true).then(response => {
                        if (response.status === 200) {
                            this.options.cd_procedimento_estabelecimento = response.data.map(pe => {
                                let p = {
                                    value: pe.id,
                                    cd_cbhpm: pe.cd_procedimento.cd_procedimento,
                                    nm_procedimento: pe.cd_procedimento.nm_procedimento,
                                    ds_procedimento: pe.ds_observacao || pe.cd_procedimento.ds_procedimento,
                                    ds_sigla: pe.cd_procedimento.ds_sigla,
                                    ds_sinonimia: pe.cd_procedimento.ds_sinonimia,
                                    ds_especialidades: pe.cd_especialidades?.map(item => (item.nm_especialidade)).join(" - "),
                                    ds_preco: pe.nr_valor_negociado
                                }
                                p.text = `${ p.cd_cbhpm } ${ p.nm_procedimento } ${ p.ds_sigla || '' } ` +
                                    ` ${ p.ds_procedimento } ${ p.ds_sinonimia || '' }` +
                                    ` - ${p.ds_especialidades}`
                                return p
                            })
                        }
                        this.getEspecialistas()
                        if (this.model.ie_tipo_agenda == 'C') {
                            this.getEspecialidades()
                        }
                        this.waitingProcedimentos = false
                    })
                }
            },
            getEspecialidades () {
                this.waitingEspecialidades = true
                this.options.cd_especialidade = []
                Agenda.getEspecialidadesByTipoAgenda(this.model.ie_tipo_agenda).then(response => {
                    if (response.status == 200) {
                        response.data.forEach(e => {
                            this.options.cd_especialidade.push({ text: e.nm_especialidade, value: e.id })
                        })
                    }
                    this.waitingEspecialidades = false
                })
            },
            getEspecialistas () {
                this.waitingEspecialistas = true
                this.options.cd_especialista = []

                Especialista.findAll().then(response => {
                    if (response.status == 200) {
                        response.data.forEach(e => {
                            this.options.cd_especialista.push({ text: e.nm_especialista, value: e.id })
                        })
                        if (this.model.ie_tipo_agenda !== "C") {
                            this.options.cd_especialista.unshift({ text: "--Sem Especialista--", value: null})
                        }
                    }
                    this.waitingEspecialistas = false
                })
            },
            checkEspecialidades (arr) {

                let maxCount = 10

                if (arr.length > maxCount) {
                    this.$toast.error(`Selecione no máximo ${ maxCount } especialidade${ maxCount == 1 ? '' : 's' }`, { duration: 3000 })
                    arr.pop()
                }
            }
        }
    }
</script>
