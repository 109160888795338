<template lang="pug">
    Panel.calendario-component(header='Calendário' v-if='filters.data && filters.cd_agenda')
        .waiting(v-if='waiting')
            ProgressSpinner(strokeWidth='2')
        .p-grid.p-fluid.p-align-end(v-else-if='list.length')
            .p-col-8
                VCalendar(ref='calendario' is-expanded locale='pt-BR' :attributes='calendarAttributes'
                    @dayclick="$emit('dayclick', $event)")
            .p-col-4
                Card
                    template(slot='content')
                        .text-caption(v-if="calendarAttributes.find(c => c.key == 'SA').dates.length")
                            <div class='caption sa'></div> Sem atendimento
                        .text-caption(v-if="calendarAttributes.find(c => c.key == 'TL').dates.length")
                            <div class='caption tl'></div> Todos livres
                        .text-caption(v-if="calendarAttributes.find(c => c.key == 'HV').dates.length")
                            <div class='caption hv'></div> Há vagas
                        .text-caption(v-if="calendarAttributes.find(c => c.key == 'TO').dates.length")
                            <div class='caption to'></div> Todos ocupados
                        .text-caption(v-if="calendarAttributes.find(c => c.key == 'B0').dates.length")
                            <div class='caption b0'></div> Bloqueado
                        .text-caption(v-if="calendarAttributes.find(c => c.key == 'F').dates.length")
                            <div class='caption fe'></div> Feriado
                        .text-caption(v-if="calendarAttributes.find(c => c.key == 'R').dates.length")
                            <div class='caption re'></div> Reservado
</template>

<style lang="scss">
    
    .calendario-component {
        .text-caption {
            font-size: 13px;
            font-weight: 700;
            .caption {
                display: inline-block;
                height: 6px;
                width: 24px;
                background-color: #888;
                margin-right: 8px;
                border-radius: 2px;
                &.sa { background-color: #718096; }
                &.tl { background-color: #38a169; }
                &.hv { background-color: #d69e2e; }
                &.to { background-color: #f7de08; }
                &.b0 { background-color: #e53e3e; }
                &.fe { background-color: #9b67d5; }
                &.re { background-color: #fda2ff; }
            }
        }
        .vc-arrows-container {
            display: none;
        }
        .vc-day {
            z-index: 0 !important;
        }
        .vc-bg-ocupados-600{
            background-color: #f7de08;
        }

        .waiting {
            padding: 80px 0;
            text-align: center;
            .p-progress-spinner {
                width: 60px;
                height: auto;
            }
        }
    }
</style>

<script>
	import VCalendar from 'v-calendar/lib/components/calendar.umd'
    import Card from 'primevue/card'
    import Panel from 'primevue/panel'
    import ProgressSpinner from 'primevue/progressspinner'

    import { Agenda } from '../../middleware'
	import moment from 'moment'
    export default {
        components: { VCalendar, Card, Panel, ProgressSpinner },
        data () {
            return {
                list: [],
                waiting: false,
                filters: {
                    cd_agenda: null,
                    data: null
                },
				calendarAttributes: [
					{key: 'SA', highlight: { color: 'gray' }, dates: [], popover: { label: 'Sem atendimento' } },
					{key: 'TL', highlight: { color: 'green' }, dates: [], popover: { label: 'Todos livres' } },
					{key: 'HV', highlight: { color: 'yellow' }, dates: [], popover: { label: 'Há vagas' } },
					{key: 'TO', highlight: { color: 'ocupados' }, dates: [], popover: { label: 'Todos ocupados' } },
					{key: 'B0', highlight: { color: 'red' }, dates: [], popover: { label: 'Bloqueado' } },
                    {key: 'F', highlight: { color: 'purple' }, dates: [], popover: { label: 'Feriado' } },
                    {key: 'R', highlight: { color: 'pink' }, dates: [], popover: { label: 'Reservado' } }
				]
            }
        },
        methods: {
            getCalendario () {
				this.list = []
				this.calendarAttributes.forEach(c => c.dates = [])
				if (this.filters.cd_agenda && this.filters.data) {
					let mes = moment(this.filters.data).format('M')
					let ano = moment(this.filters.data).format('Y')

					this.waiting = true
					Agenda.getCalendario(this.filters.cd_agenda, mes, ano).then(response => {
						this.waiting = false
						if (response.status === 200) {
							this.list = response.data
							this.list.forEach(item => {
								let find = this.calendarAttributes.find(c => c.key === item.status)
								if (find) find.dates.push(item.data)
							})
							setTimeout(() => { this.$refs.calendario.move(this.filters.data) })
						}
					})
				}
            }
        }
    }
</script>