<template lang="pug">
.main-wrapper.agenda-visualizar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-3
                    Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='back()')
                .p-col-9.ta-right
                    h1.text-header.text-secondary MedClub /
                        <router-link :to='{ name: "agenda" }'> Agendas</router-link> /
                        <b>Visualizar</b>

        SalvarDias

        Dialog.dialogBloquear(v-if='model' :contentStyle="{overflow: 'visible'}" header='Bloquear agenda' :visible.sync='dialogBloquear' :modal='true')
            form(v-if='!editarHorarios && !moverHorarios' @submit.prevent='blockAgenda()')
                .p-grid.p-fluid

                    .p-col-12.p-md-6(:class="{ 'form-group--error': submitted && $v.modelBloqueio.dt_inicio_bloqueio.$error }")
                        label.form-label Data Inicio:
                        .p-inputgroup
                            Calendar(v-model='$v.modelBloqueio.dt_inicio_bloqueio.$model' dateFormat="dd/mm/yy" :locale="ptbr"
                                :manualInput='false' :touchUI='windowInnerWidth < 576')
                            Button.p-button-danger(icon='jam jam-rubber' type='button' v-tooltip.top="'Limpar'" @click='model.dt_inicio_bloqueio=null')
                        .feedback--errors(v-if='submitted && $v.modelBloqueio.dt_inicio_bloqueio.$error')
                            .form-message--error(v-if="!$v.modelBloqueio.dt_inicio_bloqueio.required") <b>Data de início</b> é obrigatório.

                    .p-col-12.p-md-6(:class="{ 'form-group--error': submitted && $v.modelBloqueio.dt_fim_bloqueio.$error }")
                        label.form-label Data Fim:
                        .p-inputgroup
                            Calendar(v-model='$v.modelBloqueio.dt_fim_bloqueio.$model' dateFormat="dd/mm/yy" :manualInput='false'
                                :locale="ptbr" :minDate='$v.modelBloqueio.dt_inicio_bloqueio.$model' :touchUI='windowInnerWidth < 576')
                            Button.p-button-danger(icon='jam jam-rubber' type='button' v-tooltip.top="'Limpar'" @click='model.dt_fim_bloqueio=null')
                        .feedback--errors(v-if='submitted && $v.modelBloqueio.dt_fim_bloqueio.$error')
                            .form-message--error(v-if="!$v.modelBloqueio.dt_fim_bloqueio.required") <b>Data de fim</b> é obrigatório.

                    .p-col-12.p-md-6
                        label.form-label Horário de início:
                        InputMask(type='tel' v-model='modelBloqueio.hr_inicio' mask='99:99' placeholder='hh:mm')

                    .p-col-12.p-md-6
                        label.form-label Horário de fim:
                        InputMask(type='tel' v-model='modelBloqueio.hr_fim' mask='99:99' placeholder='hh:mm')

                    .p-col-12(:class="{ 'form-group--error': submitted && $v.modelBloqueio.ds_motivo_bloqueio.$error }")
                        label.form-label Motivo do Bloqueio:
                        Textarea(type='text' v-model='$v.modelBloqueio.ds_motivo_bloqueio.$model' :autoResize="true" rows="3")
                        .feedback--errors(v-if='submitted && $v.modelBloqueio.ds_motivo_bloqueio.$error')
                            .form-message--error(v-if="!$v.modelBloqueio.ds_motivo_bloqueio.minLength") <b>Motivo</b> deve ter pelo menos 2 caracteres.
                            .form-message--error(v-if="!$v.modelBloqueio.ds_motivo_bloqueio.required") <b>Motivo</b> é obrigatório.

                    .p-col-12
                        label.form-label Especialista:
                        InputText(readonly v-model='model.nm_especialista')

                .ta-center.my-4
                    ProgressSpinner.waiting-executar-spinner(v-if='waitingExecutar' strokeWidth='6')
                    Button.p-button-success(v-else label='Bloquear' type="submit")

            Panel.datatable(v-else-if='!moverHorarios' header='Lista de agendamentos')
                ProgressBar(v-if='waitingRemover' mode="indeterminate")
                DataTable(v-if='!waitingRemover' :value="cd_horario_agendamento")
                    Column(headerStyle='width: 8%;' bodyStyle='text-align: center;' field='id' header='Cód. Horário')
                    Column(headerStyle='width: 8%;' bodyStyle='text-align: center;' field='nm_paciente' header='Paciente')
                    Column(headerStyle='width: 8%;' bodyStyle='text-align: center;' field='dt_agenda_f' header='Data')
                    Column(headerStyle='width: 8%;' bodyStyle='text-align: center;' field='hr_agenda' header='Horário')
                    Column(headerStyle='width: 8%;' bodyStyle='text-align: center;' field='ie_status' header='Status')
                    Column(headerStyle='width: 8%; text-align: center')
                        template(#header) Ações
                        template(#body='props')
                            .ta-center
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    v-if='props.data.ie_status == "A"'
                                    v-tooltip.top="'Editar'"
                                    icon="jam jam-write"
                                    @click='moverHorario(props.data)'
                                )
                                Button.p-button-raised.p-button-rounded.p-button-danger(
                                    v-if='props.data.ie_status == "R"'
                                    v-tooltip.top="'Remover'"
                                    icon="jam jam-delete"
                                    @click='removerHorario(props.data)'
                                )
                .ta-center.my-4
                    Button.p-button(label='Voltar' @click='editarHorarios = false')

            form(v-if='moverHorarios' @submit.prevent='handleSubmit()')
                Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='moverHorarios=false')
                .p-grid.p-fluid
                    .p-col-12.p-md-6
                        label.form-label Data:
                        Dropdown(v-model='dataSend.cd_data' :options='options.datas'
                            optionLabel='text' optionValue='value' dataKey='value' placeholder='Selecione' @change='getHorarios()')

                    .p-col-12.p-md-6
                        label.form-label Horário:
                        Dropdown(v-model='dataSend.cd_horario' :options='options.horarios'
                            optionLabel='text' optionValue='value' dataKey='value' placeholder='Selecione')

                    .p-col-12.p-md-12
                        Button(icon='jam jam-save' label='Salvar')

        Dialog.dialogEditar(header='Editar agenda' :visible.sync='dialogEditar' :modal='true' contentStyle="overflow: auto;")
            SalvarAgenda(:isModal='true')

        Dialog.dialogApagar(v-if='model' header='Remover agenda' :visible.sync='dialogApagar' :modal='true' :contentStyle='{ overflow: "hidden !important" }')
            p Deseja remover essa agenda do especialista <b>{{ model.nm_especialista }}</b>?
            .ta-center.my-4
                ProgressBar(v-if='waitingRemoverAgenda' mode='indeterminate')
                Button.p-button-danger(v-else label='Remover' @click='remove()')

        Dialog.remover-dias(header='Remover atendimento' :visible.sync='dialogApagarDias' :modal='true')
            p Deseja remover o atendimento de <b>{{ `${ dialogApagarDias_data.nm_dia_semana_fs }, DAS ${ dialogApagarDias_data.hr_inicio_atendimento_fs } ÀS ${ dialogApagarDias_data.hr_fim_atendimento_fs }` }}</b>?
            .ta-center.mt-4
                ProgressSpinner.small-progress-spinner(v-if='waitingRemoverDias' strokeWidth='6')
                Button.p-button-danger(v-else label='Remover' @click='removeDias()')

        DialogListar(:display.sync="dialogAgendamentos" :filters="filtersAgendamentos" @close="dialogAgendamentos=false")

        DialogListar(:display.sync="dialogListarPendencias" :lista.sync="listaHorariosPendentes" @close='dialogListarPendencias=false' :conflitosAgenda='true' )

        Panel.my-2(header='Detalhes da Agenda')
            ProgressBar(v-if='waiting' mode="indeterminate")
            div(v-else)
                .p-grid.p-fluid.p-justify-center
                    .p-col-12.p-md-6
                        .p-grid.p-fluid.box-info

                            .box-featured.p-col-12.my-4
                                .p-grid.p-fluid
                                    .p-col-12.aux-2
                                        p <b>Estabelecimento: </b> {{ model.nm_estabelecimento }}
                                    .p-col-12.aux-2
                                        p <b>Procedimento(s): </b>
                                            span {{ model.nm_procedimento }} <br>
                                            div(v-if='model.cd_procedimentos !== undefined && model.cd_procedimentos.length > 0' v-for='procedimento in model.cd_procedimentos')
                                                span(v-if="procedimento.ie_status == true") &bull; {{ procedimento.ds_procedimento }} <br>
                                                span(v-else) &bull; <b>{{ procedimento.ds_procedimento }}</b> (desativado) <br>
                                    .p-col-12.aux-2(v-if="model.ie_tipo_agenda == 'C'")
                                        p <b>Especialista: </b> {{ model.nm_especialista }}
                                    .p-col-12.aux-2(v-if="model.ie_tipo_agenda == 'C'")
                                        p <b>Especialidade(s): </b>
                                            span.mtd-badge.co1(v-for='especialidade in model.cd_especialidades') {{ especialidade.nm_especialidade }}

                            .p-col-12.p-md-6.aux-2
                                p <b>Sexo: </b> {{ model.ie_sexo_f }}
                            .p-col-12.p-md-6.aux-2
                                p <b>Hora marcada: </b> {{ model.ie_hora_marcada ? 'Sim' : 'Não' }}
                            .p-col-12.p-md-6.aux-2
                                p <b>Presencial: </b> {{ model.ie_agenda_presencial ? 'Sim' : 'Não' }}
                            .p-col-12.p-md-6.aux-2
                                p <b>Por vídeo: </b> {{ model.ie_telemedicina ? 'Sim' : 'Não' }}
                            .p-col-12.p-md-6.aux-2
                                p <b>Grátis: </b> {{ model.ie_agenda_gratis ? 'Sim' : 'Não' }}
                            .p-col-12.p-md-6.aux-2
                                p <b>Quantidade de vagas: </b> {{ model.qtd_vaga }}
                            .p-col-12.p-md-6.aux-2
                                p <b>Idade: </b> De {{ model.nr_idade_minima }} a {{ model.nr_idade_maxima }} anos
                            .p-col-12.p-md-6.aux-2
                                p <b>Id Integração: </b> {{ model.id_externo }}
                            .p-col-12.aux-2.mt-4(v-if='model.ds_observacao')
                                p <b>Observações: </b>
                                Textarea(v-model='model.ds_observacao' :autoResize="true" rows="5" readonly)
                            .p-col-12.aux-2.mt-4(v-if='model.ds_termo_pronto_socorro')
                                p <b>Termo: </b>
                                Textarea(v-model='model.ds_termo_pronto_socorro' :autoResize="true" rows="5" readonly)
                            .p-col-12.p-md-6.aux-2
                                p <b>Usuario original: </b> {{ model.nm_usuario_cri }}
                            .p-col-12.p-md-6.aux-2
                                p <b>Data da criação: </b> {{ model.dt_criado }}
                            .p-col-12.p-md-6.aux-2
                                p <b>Usuario edição: </b> {{ model.nm_usuario_edi }}
                            .p-col-12.p-md-6.aux-2
                                p <b>Data da atualização: </b> {{ model.dt_atualizado }}
                            .p-col-12.aux-2(v-if='model.ds_orientacao')
                                p <b>Informações de preparo: </b>
                                Textarea(v-model='model.ds_orientacao' :autoResize="true" rows="5" readonly)

                    ProgressSpinner.p-col-12.p-md-6(v-if='waitingAtendimentos' :style="{ placeSelf:'center' }")
                    .p-col-12.p-md-6.mt-4(v-show='!waitingAtendimentos')
                        CalendarioComponent(ref='calendarioComponent' @dayclick="displayAtendimentos")
                        .mt-2(v-if='$refs.calendarioComponent')
                            label.form-label Mês
                            Calendar(
                                v-model='$refs.calendarioComponent.filters.data'
                                dateFormat="mm/yy"
                                :yearRange="`2020:${proximoAno}`"
                                view='month'
                                :locale="ptbr"
                                :manualInput='false'
                                :yearNavigator='true'
                                @input='getCalendario()')

                .ta-center
                    Button.ma-2.p-button-warning(label='Listar Bloqueios' icon="jam jam-padlock" @click="$router.push(`/agenda/listar-bloqueios/${ model.id }/`)")
                    Button.ma-2.p-button-warning(label='Bloquear' icon="jam jam-padlock" @click="dialogBloquear = true")
                    //Button.ma-2(label='Editar' icon="jam jam-write" @click="$router.push(`/agenda/salvar/${ model.id }/`)")
                    Button.ma-2(label='Editar' icon="jam jam-write" @click="dialogEditar = true")
                    Button.ma-2.p-button-danger(label='Apagar' icon="jam jam-trash" @click='dialogApagar = true')

        Panel.my-2(header='Dias de Atendimento')
            ProgressBar(v-if='waitingAtendimentos' mode="indeterminate")
            div.p-grid(v-else)
                Panel.aux-3.p-col-12.p-md-3(v-for='dia in Object.keys(diasSemanaDictionary)' :key='dia' :header='diasSemanaDictionary[dia].short')

                    div(v-if='!listDias[dia].length')
                        p.aux-1 Não possui atendimentos nesse dia.
                    DataView.my-2(v-else :value="listDias[dia]" layout="grid")
                        template(#grid="props")
                            .p-col-12(style='padding: .5em' :class="{ disabled: !props.data.ie_ativo }")
                                Panel.panel-list.ta-center(:header='`DAS ${ props.data.hr_inicio_atendimento_fs } ÀS ${ props.data.hr_fim_atendimento_fs }`'
                                    style='position: relative')
                                    .status-indicator.left(v-if='props.data.ie_integrado' v-tooltip.top="'Integrado'")
                                        .jam.jam-link(:style="{'display': inline-block}")
                                    .status-indicator.right(:class='{ off: props.data.ie_ativo == false }' v-tooltip.top="props.data.ie_ativo ? 'Habilitado' : 'Desabilitado'")
                                        span(:class="{ 'jam-check': props.data.ie_ativo == true, 'jam-close': props.data.ie_ativo == false  }").jam
                                    .ta-center
                                        p <b>De {{ props.data.dt_vigencia_inicial_f }} a {{ props.data.dt_vigencia_final_f }}</b>
                                        p <b>Das {{ props.data.hr_inicio_atendimento_f }} às {{ props.data.hr_fim_atendimento_f }}</b>
                                        p <b>Atende em feriado:</b> {{ props.data.ie_atende_feriado ? 'Sim' : 'Não' }}
                                        p <b>{{ props.data.qtd_vaga }} vagas</b>
                                        p <b> Sala: </b> {{ props.data.nm_unidade_sala ? props.data.nm_setor + ' / ' + props.data.nm_unidade_sala : 'Não' }}
                                    .ta-right.p-buttonset.mt-3
                                        Button.mr-1.p-button-raised.p-button-rounded(v-tooltip.top="'Duplicar'" icon="jam jam-files" @click="openSalvarDias(props.data, true)")
                                        Button.mr-1.p-button-raised.p-button-rounded(v-tooltip.top="'Editar'" icon="jam jam-write" @click="openSalvarDias(props.data)")
                                        Button.p-button-raised.p-button-rounded.p-button-danger(v-tooltip.top="'Remover'" icon="jam jam-minus-circle"
                                            @click='dialogApagarDias_data = props.data; dialogApagarDias = true')

                    .ta-right
                        Button.p-button-raised.p-button-rounded(icon="jam jam-plus" @click="openSalvarDias({ nm_dia_semana: dia })")

</template>

<style lang="scss">

.box-featured.p-col-12.my-4 {
    max-height: 400px; /* Defina o tamanho máximo conforme necessário */
    overflow-y: auto;
}
    .dialogBloquear {
        width: 800px;
        max-height: 600px;
    }
    .agenda-visualizar {
        .disabled {
            p { color: #aaa; }
            .p-panel-titlebar {
                span { color: #aaa; }
            }
            .p-panel-content {
                background-color: #f4f4f4 !important;
            }
        }
        .aux-1 { font-size: 13px; font-weight: 700; text-align: center; margin: 40px 0; }
        .aux-2 { padding: 2px 10px; }
        .aux-3 {
            padding: 8px;
            .p-dataview-content { border: none; padding: 0; }
        }
        .status-indicator {
            display: inline-block;
            font-size: 0;
            width: 20px;
            height: 20px;
            line-height: 20px;
            border-radius: 50%;
            position: absolute;
            top: 9px;
            color: #fff;
            &.right {
                right: 9px;
                background-color: #94c860;
                border: 1px solid #84b553;
            }
            &.left {
                left: 9px;
                background-color: dodgerblue;
                border: 1px solid royalblue;
            }
            .jam {
                font-size: 18px;
                width: 100%;
                text-align: center;
            }
            &.off {
                background-color: #d33838;
                border: 1px solid #b33030;
            }
        }
        .p-inputtextarea {
            overflow-y: auto !important;
            cursor: default;
        }
        .box-info {
            padding: 10px 20px;
        }
        .dialogEditar {
            width: 96%;
            max-width: 960px;
        }
        .dialogApagar{
            width: 40vw;
            max-width: 350px;
        }
        .box-featured {
            background-color: #f8f9fa;
            border: 1px solid #e9ecef;
            border-radius: 8px;
            padding: 10px;
            padding-top: 18px;
            p {
                font-size: 16px;
            }
        }
    }
    .remover-dias .small-progress-spinner {
        width: 29px;
        height: auto;
    }
    .waiting-executar-spinner {
        width: 29px;
        height: auto;
    }
</style>

<script>
    import Panel from 'primevue/panel'
    import ProgressBar from 'primevue/progressbar'
    import Button from 'primevue/button'
    import DataTable from 'primevue/datatable'
    import Dialog from 'primevue/dialog'
    import DataView from 'primevue/dataview'
    import SalvarDias from './SalvarDias'
    import InputText from 'primevue/inputtext'
    import Calendar from 'primevue/calendar'
    import Tooltip from 'primevue/tooltip'
    import Textarea from 'primevue/textarea'
    import Column from 'primevue/column'
    import Dropdown from 'primevue/dropdown'
    import ProgressSpinner from 'primevue/progressspinner'
    import InputMask from 'primevue/inputmask'

    import CalendarioComponent from './../Agendamento/Calendario-component'
    import SalvarAgenda from './Salvar'
    import { Agenda, BloqueioAgenda, Agendamento } from './../../middleware'
    import moment from 'moment'
    import { pCalendarLocale_ptbr } from './../../utils'
    import { required, minLength } from 'vuelidate/lib/validators'
    import DialogListar from "@/components/Agendamento/DialogListar";

    export default {
        data () {
            return {
                model:{},
                modelBloqueio: {
                    cd_agenda: null,
                    ds_motivo_bloqueio: null,
                    dt_inicio_bloqueio: null,
                    dt_fim_bloqueio: null,
                    hr_inicio: null,
                    hr_fim: null,
                },
                dataSend: {
                    id: 0,
                    cd_agenda: null,
                    cd_data: 0,
                    cd_horario: 0
                },
                cd_agenda_id: null,
                submitted: false,
                mudaHorario: false,
                waiting: true,
                waitingExecutar: false,
                waitingAtendimentos: false,
                waitingRemover: false,
                waitingRemoverDias: false,
                waitingRemoverAgenda: false,
                editarHorarios: false,
                moverHorarios: false,
                options: {
                    horarios: [],
                    datas: [],
                },
                ptbr: pCalendarLocale_ptbr,
                windowInnerWidth: window.innerWidth,
                listDias: {
                    SEGUNDA: [],
                    TERCA: [],
                    QUARTA: [],
                    QUINTA: [],
                    SEXTA: [],
                    SABADO: [],
                    DOMINGO: []
                },
                diasSemanaDictionary: {
                    'SEGUNDA': { name: 'Segunda-Feira', short: 'SEGUNDA' },
                    'TERCA': { name: 'Terça-Feira', short: 'TERÇA' },
                    'QUARTA': { name: 'Quarta-Feira', short: 'QUARTA' },
                    'QUINTA': { name: 'Quinta-Feira', short: 'QUINTA' },
                    'SEXTA': { name: 'Sexta-Feira', short: 'SEXTA' },
                    'SABADO': { name: 'Sábado', short: 'SÁBADO' },
                    'DOMINGO': { name: 'Domingo', short: 'DOMINGO' }
                },
                dialogBloquear: false,
                proximoAno: parseInt(moment().format("YYYY"))+1,
                dialogApagar: false,
                dialogApagarDias: false,
                dialogEditar: false,
                dialogApagarDias_data: {},
                dialogListarPendencias: false,
                cd_horario_agendamento: [],
                dialogSalvarDias: false,
                modelSalvarDias: {},
                dialogAgendamentos: false,
                filtersAgendamentos: {
                    dt_inicio: '',
                    dt_fim: '',
                    order: 'dt_agenda',
                    ie_status: ['A', 'EX', 'L', 'BO', 'R'],
                    ie_tipo_agenda: '',
                    especialista: null
                },
                listaHorariosPendentes: []
            }
        },
        validations () {
            let v = {
                modelBloqueio: {
                    dt_inicio_bloqueio: { required },
                    dt_fim_bloqueio: { required },
                    ds_motivo_bloqueio: { required, minLength: minLength(2) }
                }
            }
            return v
        },
        components: {
            Calendar, Panel, InputText, Dropdown, InputMask, DataTable, CalendarioComponent, SalvarAgenda,
            Column, ProgressBar, Button, Dialog, ProgressSpinner, DataView, SalvarDias, Textarea, DialogListar
        },
        directives: { tooltip: Tooltip },
        created () {
            this.waiting = true
            this.waitingAtendimentos = true

            if (isNaN(this.$route.params.id)) this.$router.go(-1)

            Agenda.find(parseInt(this.$route.params.id)).then(response => {
                if (response.status == 200) {
                    this.model = response.data
                    this.cd_agenda_id = response.data.id

                    let ie_sexo_options = {
                        'M': 'Masculino',
                        'F': 'Feminino',
                        'N': 'Não aplicável'
                    }

                    this.model.ie_sexo_f = ie_sexo_options[this.model.ie_sexo]

                    let procedimento = this.model.cd_procedimento_estabelecimento
                    if (procedimento != null)
                        this.model.nm_procedimento = procedimento.ds_observacao ? procedimento.ds_observacao : procedimento.cd_procedimento.nm_procedimento
                    else
                        this.model.nm_procedimento = ''

                    this.getAtendimentos()

                    this.waiting = false

                } else this.$router.go(-1)
            })
        },
        methods: {
            formatarData(){
                this.model.dt_criado = moment(this.model.dt_criado).format('DD/MM/YYYY')
                if (this.model.dt_atualizado){
                    this.model.dt_atualizado = moment(this.model.dt_atualizado).format('DD/MM/YYYY')
                }
            },
            blockAgenda() {
                this.submitted = true

                this.$v.modelBloqueio.$touch()
                if (this.$v.modelBloqueio.$invalid) return

                let dataSend = {
                    cd_agenda: this.cd_agenda_id,
                    dt_inicio_bloqueio: moment(this.modelBloqueio.dt_inicio_bloqueio).format('YYYY-MM-DD'),
                    dt_fim_bloqueio: moment(this.modelBloqueio.dt_fim_bloqueio).format('YYYY-MM-DD'),
                    ds_motivo_bloqueio: this.modelBloqueio.ds_motivo_bloqueio
                }

                if (this.modelBloqueio.hr_inicio) {
                    dataSend.hr_inicio = moment(this.modelBloqueio.hr_inicio,'HH:mm').format('HH:mm:00')
                }
                if (this.modelBloqueio.hr_fim) {
                    dataSend.hr_fim = moment(this.modelBloqueio.hr_fim,'HH:mm').format('HH:mm:59')
                }

                this.waitingExecutar = true
                BloqueioAgenda.bloquearAgenda(dataSend).then(response => {
                    this.waitingExecutar = false
                    if([200, 201].includes(response.status)) {
                        this.editarHorarios = false
                        this.dialogBloquear = false
                        this.$toast.success('Agenda bloqueada com sucesso.', { duration: 3000 })
                    } else {
                        if (response.data.detail) {
                            if (typeof response.data.detail == 'string') {
                                this.$toast.error(response.data.detail, { duration: 3000 })
                            } else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                        }
                        if(response.data.cd_horario_agendamento !== undefined) {
                            response.data.cd_horario_agendamento.forEach(i => {
                                i.dt_agenda_f = moment(i.dt_agenda, 'YYYY-MM-DD').format('DD/MM/YYYY')
                            })
                            this.editarHorarios = true
                            this.cd_horario_agendamento = response.data.cd_horario_agendamento
                        }
                    }
                })
            },
            back() {
                // $router.go(-1)
                if(this.$route.query.cadastro !== undefined) {
                    this.$router.push({ name: 'agenda' })
                } else {
                    this.$router.go(-1)
                }

            },
            removerHorario(data) {

                    this.waitingRemover = true
                    var dataSend = {
                        cd_horario_agendamento: data.id,
						cd_pessoa_fisica: data.cd_paciente,
						hr_agenda: data.id,
						dt_agenda: data.dt_agenda,
						cd_especialidade: data.cd_especialidade,
						cd_agenda: data.cd_agenda,
						cd_dia_atendimento: data.cd_dia_atendimento,
                        ie_reservar: 'L',
					}

					Agendamento.reservarHorario(dataSend, { cd_horario_agendamento: data.id }).then(response => {
						if (response.status == 200) {
							//this.agendamentos = this.agendamentos.filter(agendamento => agendamento.id != this.waitingRemover)
							this.waitingRemover = false
							this.$toast.success('Agendamento removido', { duration: 3000 })

						}
                    })
            },
            handleSubmit() {
                if (this.dataSend.cd_horario == null) {
                    this.$toast.error('Informe o novo horário do agendamento', { duration: 3000 })
                } else if (this.dataSend.cd_horario == 0) {
                    this.$toast.info('Agendamento sem modificação', { duration: 3000 })
                } else {
                    let dataSend = { cd_horario_novo: this.dataSend.cd_horario}
                    Agendamento.transferirHorario(this.dataSend.id, dataSend).then(response => {
                        if (([200, 201]).includes(response.status)) {
                            this.$toast.success('Agendamento atualizado!', { duration: 3000 })
                            this.moverHorarios = false;
                        } else if (response.status == 400) {
                            if (response.data.detail) {
                                if (typeof response.data.detail == 'string')
                                    this.$toast.error(response.data.detail, { duration: 3000 })
                                else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                            }
                        }
                    })
                }
            },
            moverHorario (data) {
                this.moverHorarios = true;
                this.options.datas = [{ value: data.dt_agenda, text: data.dt_agenda_f }]

                this.dataSend.cd_data = data.dt_agenda;
                this.dataSend.cd_agenda = data.cd_agenda;
                this.dataSend.id = data.id;

                let params = { id: this.dataSend.id, ie_status: 'L' }

                Agenda.getDatasDisponiveis(this.dataSend.cd_agenda, params).then(response => {
                    if (response.status == 200)
                        response.data.forEach(data => {
                            if (data.dt_agenda != moment(this.model.dt_agenda, 'DD/MM/YYYY').format('YYYY-MM-DD'))
                                this.options.datas.push({ value: data.dt_agenda, text: moment(data.dt_agenda).format('DD/MM/YYYY') })
                        })
                    this.options.datas.sort((a, b) => moment(a.text, 'DD/MM/YYYY')._d - moment(b.text, 'DD/MM/YYYY')._d)
                    this.getHorarios();
                })
            },
            getHorarios() {
                this.dataSend.cd_horario = null
                this.options.horarios = []

                let params = { dt_agenda: this.dataSend.cd_data, ie_status: 'L' }

                Agenda.getHorariosDisponiveis(this.dataSend.cd_agenda, params).then(response => {
                    if (response.status == 200) {
                        response.data.forEach(horario => {
                            if (this.model.hr_agenda_f != horario.hr_agenda) {
                                this.options.horarios.push({ value: horario.id, text: horario.hr_agenda })
                            }
                        })
                    }
                    this.options.horarios.sort((a, b) => moment(a.text, 'HH:mm', true)._d - moment(b.text, 'HH:mm', true)._d)
                })
            },
            getCalendario () {
                this.$refs.calendarioComponent.filters.cd_agenda = this.model.id
                this.$refs.calendarioComponent.getCalendario()
            },
            remove () {
                this.waitingRemoverAgenda = true
                Agenda.remove(this.model.id).then(({status, data}) => {
                    if (status == 200) {
                        this.$toast.info('Agenda removida com sucesso', { duration: 3000 })
                        this.dialogApagar = false
                        this.$router.push({ name: 'agenda' })
                    } else {
                        this.listaHorariosPendentes = data.horarios
                        this.dialogListarPendencias = true
                        this.$toast.error(data.detail, { duration: 4000 })
                    }
                    this.waitingRemoverAgenda = false
                })
            },
            removeDias () {
                this.waitingRemoverDias = true
                Agenda.removeAtendimento(this.model.id, this.dialogApagarDias_data.id).then(response => {
                    this.waitingRemoverDias = false
                    if ([200, 204].includes(response.status)) {
                        this.$toast.info('Dia de atendimento removido com sucesso', { duration: 3000 })
                        this.dialogApagarDias = false
                        this.getAtendimentos()
                    }
                })
            },
            getAtendimentos () {
                this.waitingAtendimentos = true
                Object.keys(this.listDias).forEach(dia => this.listDias[dia] = [])
                Agenda.getAtendimentos(this.model.id).then(response => {
                    if (response.status == 200) {
                        response.data.forEach(i => {
                            i.nm_dia_semana_f = this.diasSemanaDictionary[i.nm_dia_semana].name
                            i.nm_dia_semana_fs = this.diasSemanaDictionary[i.nm_dia_semana].short
                            i.dt_vigencia_inicial_f = moment(i.dt_vigencia_inicial).format('DD/MM/YYYY')
                            i.dt_vigencia_final_f = moment(i.dt_vigencia_final).format('DD/MM/YYYY')
                            i.hr_inicio_atendimento_f = moment(i.hr_inicio_atendimento, 'HH:mm:ss').format('HH:mm')
                            i.hr_fim_atendimento_f = moment(i.hr_fim_atendimento, 'HH:mm:ss').format('HH:mm')
                            let _d = (str => str.substr(3, 2) == '00' ? `${ str.substr(0, 2) }h` : `${ str.substr(0, 2) }h${ str.substr(3, 2) }`)
                            i.hr_inicio_atendimento_fs = _d(i.hr_inicio_atendimento_f)
                            i.hr_fim_atendimento_fs = _d(i.hr_fim_atendimento_f)
                            this.listDias[i.nm_dia_semana].push(i)
                        })
                    }

                    this.waitingAtendimentos = false
                    this.$refs.calendarioComponent.filters.data = moment()._d
                    this.getCalendario()
                    this.formatarData()
                })
            },
            openSalvarDias (model, copy) {

                this.modelSalvarDias = {
                    cd_agenda: this.model.id,
                    qtd_duracao_atendimento: 0,
                    nm_dia_semana: '',
                    hr_inicio_atendimento: '',
                    hr_fim_atendimento: '',
                    dt_vigencia_inicial: moment().format('DD/MM/YYYY'),
                    dt_vigencia_final: moment().add(180, 'days').format('DD/MM/YYYY'),
                    qtd_vaga: undefined,
                    qtd_retorno: undefined,
                    ie_atende_feriado: false,
                    ie_ativo: true,
                    ie_integrado: false,
                    unidade_sala: '',
                    cd_setor: 0,
                }

                if (model) {
                    Object.keys(this.modelSalvarDias).forEach(k => {
                        if ( (['dt', 'hr']).includes(k.substr(0, 2)) ) {
                            if (model[`${ k }_f`]) this.modelSalvarDias[k] = model[`${ k }_f`]
                        } else if(!(['cd_agenda', 'qtd_duracao_atendimento', 'ie_ativo']).includes(k)) this.modelSalvarDias[k] = model[k]
                    })
                    if (model.id) {
                        if (! copy) {
                            this.modelSalvarDias.id = model.id
                            this.modelSalvarDias.nm_usuario_cri = model.nm_usuario_cri
                            this.modelSalvarDias.dt_criado_f = moment(model.dt_criado).format('DD/MM/YYYY HH:mm')
                            if (model.dt_atualizado) {
                                this.modelSalvarDias.nm_usuario_edi = model.nm_usuario_edi
                                this.modelSalvarDias.dt_atualizado_f = moment(
                                    model.dt_atualizado).format('DD/MM/YYYY HH:mm')
                            }
                        }
                        this.modelSalvarDias.ie_ativo = model.ie_ativo
                        this.modelSalvarDias.ie_integrado = model.ie_integrado
                        this.modelSalvarDias.unidade_sala = model.unidade_sala
                        this.modelSalvarDias.cd_setor = model.cd_setor
                    }
                }
                this.dialogSalvarDias = true
            },
            displayAtendimentos(ev) {
                const key = ev.popovers[0]?.key
                if (['HV', 'TO', 'BO', 'TL'].includes(key)) {
                    this.filtersAgendamentos.cd_agenda = this.model.id
                    this.filtersAgendamentos.dt_inicio = ev.id
                    this.filtersAgendamentos.dt_fim = ev.id
                    this.filtersAgendamentos.ie_tipo_agenda = this.model.ie_tipo_agenda
                    this.dialogAgendamentos = true
                }
            }
        }
    }
</script>
