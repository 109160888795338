<template lang="pug">
Dialog.dialog-salvarDias(header=`Salvar atendimento` :visible.sync='$parent.dialogSalvarDias' :modal='true')
    form(@submit.prevent='handleSubmit()')
        .p-grid.p-fluid.p-align-end
            .p-col-12.p-md-6(:class="{ 'form-group--error': submitted && $v.model.nm_dia_semana.$error }")
                label.form-label Dia da semana:
                Dropdown(
                    v-model='$v.model.nm_dia_semana.$model'
                    :options='options.nm_dia_semana'
                    :disabled='model.id != undefined'
                    dataKey='value'
                    optionLabel='text'
                    optionValue='value'
                    placeholder='Selecione'
                )
                .feedback--errors(v-if='submitted && $v.model.nm_dia_semana.$error')
                    .form-message--error(v-if="!$v.model.nm_dia_semana.required") <b>Dia da semana</b> é obrigatório.

            .p-col-6.p-md-3(:class="{ 'form-group--error': submitted && $v.model.hr_inicio_atendimento.$error }")
                label.form-label Horário de início:
                InputMask(type='tel' v-model='$v.model.hr_inicio_atendimento.$model' mask='99:99' placeholder='hh:mm' :disabled="model.ie_integrado")
                .feedback--errors(v-if='submitted && $v.model.hr_inicio_atendimento.$error')
                    .form-message--error(v-if="!$v.model.hr_inicio_atendimento.required") <b>Horário de início</b> é obrigatório.

            .p-col-6.p-md-3(:class="{ 'form-group--error': submitted && $v.model.hr_fim_atendimento.$error }")
                label.form-label Horário de fim:
                InputMask(type='tel' v-model='$v.model.hr_fim_atendimento.$model' mask='99:99' placeholder='hh:mm' :disabled="model.ie_integrado")
                .feedback--errors(v-if='submitted && $v.model.hr_fim_atendimento.$error')
                    .form-message--error(v-if="!$v.model.hr_fim_atendimento.required") <b>Horário de fim</b> é obrigatório.
            
            .p-col-12.p-md-6
                label.form-label Setor:
                Dropdown(
                    v-model='$v.model.cd_setor.$model'
                    :options='options.setores'
                    dataKey='value'
                    optionLabel='label'
                    optionValue='value'
                    placeholder='Selecione o Setor'
                    @change='zerarSala()'
                )

            .p-col-12.p-md-6
                label.form-label Sala:
                Dropdown(
                    v-model='$v.model.unidade_sala.$model'
                    :options='options.unidades[model.cd_setor]'
                    dataKey='value'
                    optionLabel='label'
                    optionValue='value'
                    placeholder='Selecione a Sala'
                )

            .p-col-12.p-md-6(:class="{ 'form-group--error': submitted && $v.model.dt_vigencia_inicial.$error }")
                label.form-label Data inicial de vigência:
                .p-inputgroup
                    Calendar( v-model='$v.model.dt_vigencia_inicial.$model' dateFormat="dd/mm/yy" :locale="ptbr"
                        :manualInput='false' :minDate='getMinDate()' :maxDate='model.dt_vigencia_final' @date-select='setDateMax()' :disabled="model.ie_integrado")
                    Button(label='Hoje' type='button' icon='jam jam-calendar' @click='setDateToday("dt_vigencia_inicial")')
                .feedback--errors(v-if='submitted && $v.model.dt_vigencia_inicial.$error')
                    .form-message--error(v-if="!$v.model.dt_vigencia_inicial.required") <b>Data inicial</b> é obrigatório.

            .p-col-12.p-md-6(:class="{ 'form-group--error': submitted && $v.model.dt_vigencia_final.$error }")
                label.form-label Data final de vigência:
                .p-inputgroup
                    Calendar( v-model='$v.model.dt_vigencia_final.$model' dateFormat="dd/mm/yy" :locale="ptbr"
                        :manualInput='false' :minDate='model.dt_vigencia_inicial' :maxDate='getMaxDate()' :disabled="model.ie_integrado")
                    Button(label='Máxima' type='button' icon='jam jam-calendar' @click='setDateMax("dt_vigencia_final")')
                .feedback--errors(v-if='submitted && $v.model.dt_vigencia_final.$error')
                    .form-message--error(v-if="!$v.model.dt_vigencia_final.required") <b>Data final</b> é obrigatório.

            .p-col-12.p-md-6(:class="{ 'form-group--error': submitted && $v.model.qtd_vaga.$error }")
                label.form-label Quantidade de vagas:
                InputNumber(v-model='$v.model.qtd_vaga.$model' mode='decimal' :min="-1" :max="101" :disabled="model.ie_integrado")
                .feedback--errors(v-if='submitted && $v.model.qtd_vaga.$error')
                    .form-message--error(v-if="!$v.model.qtd_vaga.required") <b>Qtd. de vagas</b> é obrigatório.

            .p-col-12.p-md-6(:class="{ 'form-group--error': submitted && $v.model.qtd_retorno.$error }")
                label.form-label Quantidade de retorno:
                InputNumber(v-model='$v.model.qtd_retorno.$model' mode='decimal' :min="-1" :max="101" :disabled="model.ie_integrado")
                .feedback--errors(v-if='submitted && $v.model.qtd_retorno.$error')
                    .form-message--error(v-if="!$v.model.qtd_retorno.required") <b>Qtd. de retorno</b> é obrigatório.

            .p-col-12.mt-2.mb-2(v-if="model.id")
                .p-grid
                    .p-col-12.p-md-6.aux-2
                        span <b>Usuário criação: </b> {{ model.nm_usuario_cri }}
                    .p-col-12.p-md-6.aux-2
                        span <b>Data da criação: </b> {{ model.dt_criado_f }}
                    .p-col-12.p-md-6.aux-2
                        span <b>Usuário edição: </b> {{ model.nm_usuario_edi }}
                    .p-col-12.p-md-6.aux-2
                        span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}

            .p-col-4.ta-center
                label.form-label Atende em feriado:
                InputSwitch(v-model='model.ie_atende_feriado' :disabled="model.ie_integrado")

            .p-col-4.ta-center
                label.form-label Habilitado:
                InputSwitch(v-model='model.ie_ativo' :disabled="model.ie_integrado")

            .p-col-4.ta-center
                ProgressSpinner.small-progress-spinner(v-if='waitingSubmit' strokeWidth='6')
                Button(v-else label='Salvar' icon='jam jam-save')

</template>

<style lang="scss">
    .dialog-salvarDias {
        width: 100%;
        max-width: 800px;
        max-height: auto;
        @media all and (max-width: 576px) {
            position: fixed;
            overflow-y: scroll;
            top: 0; right: 0; bottom: 0; left: 0;
            background-color: #fff;
            .p-dialog-content, .p-dialog-titlebar {
                border: none !important;
            }
        }
        .p-dialog-content {
            overflow-y: visible !important;
        }
        .p-dropdown.p-disabled .p-inputtext, .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
        .small-progress-spinner {
            width: 29px;
            height: auto;
        }
    }
    .p-datepicker-mask-scrollblocker {
        display: none !important;
    }
</style>

<script>
    import Dialog from 'primevue/dialog'
    import ProgressBar from 'primevue/progressbar'
    import Dropdown from 'primevue/dropdown'
    import InputMask from 'primevue/inputmask'
    import InputNumber from 'primevue/inputnumber'
    import Panel from 'primevue/panel'
    import ProgressSpinner from 'primevue/progressspinner'
    import Calendar from 'primevue/calendar'
    import Button from 'primevue/button'
    import InputSwitch from 'primevue/inputswitch'

    import moment from 'moment'
    import { required } from 'vuelidate/lib/validators'
    import { pCalendarLocale_ptbr } from './../../utils'
    import { Agenda, UnidadesMedclub } from '../../middleware'
    export default {
        components: { Dialog, ProgressBar, ProgressSpinner, Panel, Dropdown, InputMask,
            Calendar, InputNumber, Button, InputSwitch },
        watch: {
            '$parent.modelSalvarDias': function (val) {
                this.model = val
                this.model.dt_vigencia_final = moment(this.model.dt_vigencia_final, 'DD/MM/YYYY')._d
                this.model.dt_vigencia_inicial = moment(this.model.dt_vigencia_inicial, 'DD/MM/YYYY')._d
            }
        },
        data () {
            return {
                model: {},
                submitted: false,
                waitingSubmit: false,
                ptbr: pCalendarLocale_ptbr,
                setor: 0,
                windowInnerWidth: window.innerWidth,
                options: {
                    nm_dia_semana: [
                        { text: 'Segunda-Feira', value: 'SEGUNDA' },
                        { text: 'Terça-Feira', value: 'TERCA' },
                        { text: 'Quarta-Feira', value: 'QUARTA' },
                        { text: 'Quinta-Feira', value: 'QUINTA' },
                        { text: 'Sexta-Feira', value: 'SEXTA' },
                        { text: 'Sábado', value: 'SABADO' },
                        { text: 'Domingo', value: 'DOMINGO' }
                    ],
                }
            }
        },
        created() {
            this.getUnidades()
        },
        validations () {
            let v = {
                model: {
                    nm_dia_semana: { required },
                    hr_inicio_atendimento: { required },
                    hr_fim_atendimento: { required },
                    dt_vigencia_inicial: { required },
                    dt_vigencia_final: { required },
                    qtd_vaga: { required },
                    qtd_retorno: { required },
                    ie_atende_feriado: {},
                    ie_ativo: {},
                    ie_integrado: {},
                    unidade_sala: {},
                    cd_setor: {},
                }
            }
            return v
        },
        methods: {
            setDateToday (key) {
                this.model[key] = moment()._d
            },
            getMinDate () {
                return moment()._d
            },
            setDateMax (key) {
                if (key) this.model[key] = this.getMaxDate()
                if (this.model.dt_vigencia_final > this.getMaxDate()) this.model.dt_vigencia_final = this.getMaxDate()
            },
            getMaxDate () {
                return moment(this.model.dt_vigencia_inicial).add(180, 'days')._d
            },
            handleSubmit () {
                this.submitted = true
                this.$v.$touch()
                if (this.$v.$invalid) return 0
                this.submitted = false

                let dataSend = Object.assign({}, this.model)

                dataSend.dt_vigencia_inicial = moment(dataSend.dt_vigencia_inicial, 'DD/MM/YYYY').format('YYYY-MM-DD')
                dataSend.dt_vigencia_final = moment(dataSend.dt_vigencia_final, 'DD/MM/YYYY').format('YYYY-MM-DD')

                if(dataSend.ie_integrado == true){ dataSend.alterar_setor_sala = true }
                console.log(dataSend)
                this.waitingSubmit = true
                Agenda.saveAtendimento(this.$parent.model.id, dataSend).then(response => {
                    this.waitingSubmit = false
                    if (([200, 201]).includes(response.status)) {
                        this.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })
                        this.$parent.dialogSalvarDias = false
                        this.$parent.getAtendimentos()
                    } else if (response.status == 400) {
                        if (response.data.detail) {
                            if (typeof response.data.detail == 'string')
                                this.$toast.error(response.data.detail, { duration: 9000 })
                            else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                        }
                    }
                });
                
            },
            getUnidades (){
                this.options.unidades = {};
                this.options.setores = [];
                UnidadesMedclub.findAllSala().then(response=>{
                    if (response.status == 200) {

						this.options.unidades[0] = [{label: "- Selecione um Setor primeiro -", value: null}];

                        response.data.forEach(e => {
                            if (!this.options.unidades[e.cd_setor]){
                                this.options.unidades[e.cd_setor] = []
                            }

                            this.options.unidades[e.cd_setor].push(
                                {label: e.nm_sala, value: e.id
                            })

                            const setorExistente = this.options.setores.some(s => s.value === e.cd_setor);
                            if (!setorExistente) {
                                this.options.setores.push({label: (e.nm_setor), value: e.cd_setor});
                            }
                        })

                    }
                })
            },
            zerarSala(){
                this.model.unidade_sala = null;
            }
        }
    }
</script>
