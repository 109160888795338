<template lang="pug">
Dialog.agendamentos-dialog.mx-6(header='Lista de agendamentos' :visible.sync="show" :modal="true" :style="{widht: '800px'}" :contentStyle='{ overflow: "auto !important", textAlign: "-webkit-center"}')
        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else-if='!list.length')
            p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
        span(v-else)
            DataView.dataview.my-2(:value="list" layout="grid")
                template(#grid="props")
                    .p-col-12(style="padding: .5em")
                        Panel.panel-list.ta-center(:header="props.data?.nm_paciente?.split(' ')[0] ?? ''")
                            .ta-left
                                p <b>Horário:</b> {{ props.data.dt_hr_agenda_f }}
                                p <b>Nome do paciente:</b> {{ props.data.nm_paciente }}
                                p <b>Tipo: </b>
                                    span {{ props.data.ie_retorno ? 'Retorno' : 'Normal' }}
                                p <b>Situação: </b>
                                    span.mtd-badge(:class="{ co3: props.data.ie_status == 'A', co5: props.data.ie_status == 'EX' }") {{ props.data.ie_status_f }}
                                p <b>Telefone: </b>
                                    span(style='display:inline-block;vertical-align:middle') {{ props.data.nr_telefone_paciente_f }}
                                        a.whatsapp-link(v-if='props.data.nr_telefone_paciente' :href="`https://api.whatsapp.com/send?phone=${ props.data.nr_telefone_paciente_ws }`" target='_blank')
                                            i(class='jam jam-whatsapp')
                            .ta-right.mt-3(v-if="props.data.cd_paciente")
                                a.p-button.p-button-raised.p-button-rounded(:href="`/paciente/salvar/${ props.data.cd_paciente }/`" target="_blank" style="padding:6px;")
                                    i(class='jam jam-user')
                                    span Dados do Paciente

            DataTable.datatable(:value="list")
                Column(headerStyle='width: 15%;' bodyStyle='text-align: center;' header='Horário')
                    template(#body='props')
                        p
                            em {{ props.data.dt_hr_agenda_f }}
                Column(headerStyle='width: 30%;' header='Nome do paciente')
                    template(#body='props')
                        p
                            span {{props.data.nm_paciente}}<br />
                            em(v-if='props.data.nm_paciente' :style="{fontSize: '10px'}") Agendado em: {{props.data.dt_agendamento_f}}
                Column(headerStyle='width: 10%;' bodyStyle='padding: 0;' header='Situação')
                    template(#body='props')
                        .cell(:class="{\
                                    ex: props.data.ie_status == 'EX',\
                                    a: props.data.ie_status == 'A',\
                                    l: props.data.ie_status == 'L',\
                                    bo: props.data.ie_status == 'BO',\
                                    re: props.data.ie_status == 'R'\
                                }")
                            span {{ props.data.ie_status_f }} <br />
                            span(v-if="['EX','A'].includes(props.data.ie_status)" style='font-size:10px') ({{props.data.ie_unidade_f}})
                Column(headerStyle='width: 10%;' header='Tipo' bodyStyle='text-align:center')
                    template(#body='props')
                        span {{ props.data.ie_retorno ? 'Retorno' : 'Normal' }}
                Column(headerStyle='width: 20%;' field='nr_telefone_paciente_f' header='Telefone')
                    template(#body='props')
                        p.ta-center(style='margin:0')
                            span(style='display:inline-block;vertical-align:middle') {{ props.data.nr_telefone_paciente_f }}
                            a.whatsapp-link(v-if='props.data.nr_telefone_paciente' :href="`https://api.whatsapp.com/send?phone=${ props.data.nr_telefone_paciente_ws }`" target='_blank')
                                i(class='jam jam-whatsapp')
                Column(headerStyle='width: 15%; text-align: center')
                    template(#header) Ações
                    template(#body='{data}' :style="{text-align:'center'}")
                        .ta-center(v-if="data.cd_paciente")
                            ProgressSpinner(v-if='waitingRemover.includes(data.id)' strokeWidth='3' :style="{width:'2.357em', height:'2.357em'}")
                            span(v-else)
                                a(:href="`/paciente/salvar/${ data.cd_paciente }/`" target="_blank")
                                    Button.p-button.p-button-raised.p-button-rounded.p-button-icon-only.btn-user.p-btn-secondary(
                                        v-tooltip.top="'Dados do Paciente'"
                                        icon='jam jam-user')
                                Button.p-button.p-button-raised.p-button-rounded.p-button-icon-only.btn-user.p-button-danger(
                                    v-if="conflitosAgenda && data.ie_status == 'R'"
                                    ref="desagendar" 
                                    v-tooltip.top="'Cancelar Reserva'" 
                                    @click="confirmarRemover = data" 
                                    icon="jam jam-close")
                                a(v-if="conflitosAgenda && data.ie_status == 'A'" :href="`/transacoes-financeiras/visualizar/${ data.transacao_financeira.cd_transacao_financeira }/`" target="_blank")
                                    Button.p-button.p-button-raised.p-button-rounded.p-button-icon-only.btn-user.p-button-secondary(
                                        ref="reagendar"
                                        v-tooltip.top="'Ver Transação'" 
                                        icon="jam jam-write-f")
        ConfirmDialog(
            :display='!!confirmarRemover'
            text='Tem certeza que deseja remover esta reserva ?'
            @confirmed="removerHorario(confirmarRemover)"
            @close='confirmarRemover = false')
</template>

<style lang="scss">
    .p-dialog {
        width: 60vw;
    }
    .agendamentos-dialog {
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            td {
                height: 1px;
                word-wrap: break-word;
                .cell {
                    text-align: center;          
                    &.ex, &.a, &.l, &.r, &.bo, &.re {
                        height: 100%;
                        display: grid;
                        align-content: center;
                    }
                    &.ex  {  background-color: #e4f8e1; }
                    &.a  { background-color: #faf3dd; }
                    &.l  { background-color: #e1f0f8; }
                    &.r  { background-color: #f1e1f8; }
                    &.bo  { background-color: #fc9595; }
                    &.re  { background-color: #fda2ff; }
                }
            }
        }
        .p-tabview-panels {
            padding: 0 !important;
            border: none !important;
        }
        .p-tabview {
            padding-bottom: 0;
        }
        .p-dropdown-filter-container {
            width: 100%;
        }
        .whatsapp-link {
            margin-left: 8px;
            background-color: #0da64d;
            color: #fff;
            font-weight: 700;
            border-radius: 50%;
            padding: 4px;
            display: inline-block;
            vertical-align: middle;
            width: 28px;
            height: 28px;
            line-height: 24px;
            text-align: center;
            transition: 0.25s;
            &:hover {
                background-color: #0a7d3a;
            }
        }
        .btn-user {
            margin: .3rem;
        }
        .cursor-n-allowed {
            cursor: not-allowed;
        }
        .dialog-confirm {
            width: 30vw;
        }
    }
</style>

<script>
    import Column from 'primevue/column'
    import DataTable from 'primevue/datatable'
    import DataView from 'primevue/dataview'
    import Dialog from 'primevue/dialog'
    import Panel from 'primevue/panel'
    import Button from 'primevue/button'
    import ProgressBar from 'primevue/progressbar'
    import ProgressSpinner from "primevue/progressspinner";
    import Tooltip from 'primevue/tooltip'
    import ConfirmDialog from '../CustomComponents/ConfirmDialog.vue'

    import {Agendamento} from "@/middleware"
    import moment from "moment"
    import {fixTelefoneFormat} from "@/utils"

    export default {
        name: "DialogListar",
        components: { Column, ConfirmDialog, DataTable, DataView, Dialog, ProgressBar, Panel, Button, ProgressSpinner },
        directives: { tooltip: Tooltip },
        props: ['display', 'filters', 'lista', 'conflitosAgenda'],
        computed: {
            show: {
                get() {
                    if (this.display && this.filters) this.applyFilters()
                    return this.display
                },
                set(value) {
                    if (!value) {
                        this.$emit('close')
                    }
                }
            }
        },
        watch: {
            filters() {
                this.applyFilters()
            },
            lista(){
                if(this.lista) 
                    this.formatData(this.lista)
            }
            
        },
        data() {
            return {
                waiting: false,
                list: [],
                waitingRemover: [],
                confirmarRemover: false,
                ie_status_options: {
                    'A': 'Agendado',
                    'L': 'Livre',
                    'EX': 'Executado',
                    'BO': 'Bloqueado',
                    'R': 'Reservado'
                }
            }
        },
        methods: {
            applyFilters () {
                let params = {}
                Object.keys(this.filters).forEach((key) => {
                    if (this.filters[key]) {
                        if (key === 'ie_status') params[key] = this.filters[key].toString()
                        else if (key.substr(0, 3) === 'dt_') {
                            params[key] = moment(this.filters[key]).format('YYYY-MM-DD')
                        }
                        else params[key] = this.filters[key]
                    }
                })
                this.getList(params)
            },
            getList (params) {
                this.list = []
                this.waiting = true
                Agendamento.findAll(params).then(response => {
                    if (response.status == 200) 
                        this.formatData(response.data)
                    this.waiting = false
                })
            },
            formatData (data) {
                data.forEach(d => {
                    d.dt_hr_agenda_f = moment(d.dt_agenda + ' ' + d.hr_agenda, 'YYYY/MM/DD hh:mm:ss').format('DD/MM/YYYY hh:mm')
                    d.ie_status_f = this.ie_status_options[d.ie_status]
                    d.ie_unidade_f = d.ie_unidade ? 'Unidade' : 'App'
                    d.dt_agendamento_f = moment(d.dt_agendamento).format('DD/MM/YYYY HH:mm')
                    if (d.nr_telefone_paciente) {
                        d.nr_telefone_paciente_f = fixTelefoneFormat(d.nr_telefone_paciente)
                        d.nr_telefone_paciente_ws = d.nr_telefone_paciente.match(/\d+/g).join('')
                        if (d.nr_telefone_paciente_ws.substr(0, 2) === '55') 
                            d.nr_telefone_paciente_ws = `${ d.nr_telefone_paciente_ws }`
                         else 
                            d.nr_telefone_paciente_ws = `55${ d.nr_telefone_paciente_ws }`
                        
                    }
                })
                this.list = data
            },
            removerHorario(data){
                this.waitingRemover.push(data.id)
                var dataSend = {
                    cd_horario_agendamento: data.id,
                    cd_pessoa_fisica: data.cd_paciente,
                    hr_agenda: data.id,
                    dt_agenda: data.dt_agenda,
                    cd_especialidade: data.cd_especialidade,
                    cd_agenda: data.cd_agenda,
                    cd_dia_atendimento: data.cd_dia_atendimento,
                    ie_reservar: 'L',
                }

                for (let prop in dataSend) if(!dataSend[prop]) delete dataSend[prop]
                
                Agendamento.reservarHorario(dataSend, { cd_horario_agendamento: data.id }).then(response => {
                    if (response.status == 200) {
                        this.waitingRemover.splice(this.waitingRemover.indexOf(data.id), 1)

                        for(const prop in data) data[prop] = response.data[prop]

                        data.ie_status_f = this.ie_status_options[data.ie_status]

                        this.$toast.success('Agendamento removido', { duration: 3000 })
                    } else 
                        this.$toast.error(response.data)
                })
            }
        }
    }
</script>
